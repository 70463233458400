import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import TagManager from 'react-gtm-module'
import BrigadeValencia from "../../../components/developers/brigadedeveloper/ourproperties/BrigadeValencia";

const favicon_logo = require("../../../images/favicon.png");

const BrigadeValenciaPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <BrigadeValencia />
    </>
  )
}

export default BrigadeValenciaPage
